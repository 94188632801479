import { z } from 'zod';
import { dateString } from '../../infrastructure/schema';
import { Endpoint, FilterOperations, Method } from '../../infrastructure';
import { ConfidenceLevel } from '../projects';

//Revenue data
export const PlanYearlyRevSchema = z.object({
  yyear: z.number(),
  ymonth: z.number(),
  client_name: z.string(),
  client_id: z.string(),
  is_pre_client: z.number(),
  project_name: z.string(),
  project_id: z.string(),
  isproject_a_lead: z.number(),
  islead: z.boolean(),
  is_pre_opp: z.number(),
  isprojected: z.number(),
  month_revenue: z.number(),
  end_date_certainty: z.string(),
  plan_revenue_comment: z.string(),
  plan_revenue_comment_dtupdate: dateString,
  activepositions: z.number(),
  confidence_level: z.nativeEnum(ConfidenceLevel),
});
export type PlanYearlyRev = z.infer<typeof PlanYearlyRevSchema>;

//Available cost Data
export const PlanYearlyAvailableCostSchema = z.object({
  yyear: z.number(),
  ymonth: z.number(),
  description: z.string(),
  is_assigned: z.boolean(),
  plan_category: z.string(),
  amount: z.number(),
});
export type PlanYearlyAvailableCost = z.infer<typeof PlanYearlyAvailableCostSchema>;

//Cost data
export const PlanYearlyCostSchema = z.object({
  yyear: z.number(),
  ymonth: z.number(),
  plan_category: z.string(),
  plan_section: z.string(),
  amount: z.number(),
  department: z.string(),
  is_reimbursement: z.boolean(),
  is_lead_cost: z.boolean(),
});
export type PlanYearlyCost = z.infer<typeof PlanYearlyCostSchema>;

export const PlanYearlyProjectRevSummarySchema = z.object({
  ymonth: z.number(),
  month_revenue: z.number(),
});

export type PlanYearlyProjectRevSummary = z.infer<typeof PlanYearlyProjectRevSummarySchema>;

export const PlanYearlyRevSummarySchema = z.object({
  ymonth: z.number(),
  month_revenue: z.number(),
});
export type PlanYearlyRevSummary = z.infer<typeof PlanYearlyRevSummarySchema>;

//Body
export const PlanYearlySchema = z.object({
  planYearlyRev: z.array(PlanYearlyRevSchema),
  planYearlyCost: z.array(PlanYearlyCostSchema),
  planYearlyAvailableCost: z.array(PlanYearlyAvailableCostSchema),
  planYearlyProjectedRevSummary: z.array(PlanYearlyProjectRevSummarySchema),
  planYearlyRevSummary: z.array(PlanYearlyRevSummarySchema),
});
export type PlanYearly = z.infer<typeof PlanYearlySchema>;

//Filters
export enum LeadsStaffItFilter {
  'Exclude' = 'Exclude',
  'Include' = 'Include',
  'Only' = 'Only',
}

export enum ProjectedRevenueFilter {
  'Exclude' = 'Exclude',
  'Include' = 'Include',
  'Only' = 'Only',
}

export enum PreOppRevenueFilter {
  'Exclude' = 'Exclude',
  'Include' = 'Include',
  'Only' = 'Only',
}

export enum ListPreLeadsFilter {
  'Exclude' = 'Exclude',
  'Include' = 'Include',
  'Only' = 'Only',
}
export const ListPreLeadsFilterSchema = z.nativeEnum(ListPreLeadsFilter);

export const LeadsStaffItFilterSchema = z.nativeEnum(LeadsStaffItFilter);
export const PreOppRevenueFilterSchema = z.nativeEnum(PreOppRevenueFilter);

export const ProjectedRevenueFilterSchema = z.nativeEnum(ProjectedRevenueFilter);

export const TotalAnalysisFilterSchema = z.object({
  firstColumnRef: z.string(),
  secondColumnRef: z.string(),
  direction: z.string(),
});
export type TotalAnalysisFilter = z.infer<typeof TotalAnalysisFilterSchema>;

export const PlanYearlyFiltersSchema = z.object({
  yYear: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()),
  leadsStaffIt: z.record(z.enum([FilterOperations.EQUAL]), LeadsStaffItFilterSchema),
  preOpps: z.record(z.enum([FilterOperations.EQUAL]), PreOppRevenueFilterSchema),
  confidenceLevel: z.record(z.enum([FilterOperations.IN]), z.nativeEnum(ConfidenceLevel).array()),
});
export type PlanYearlyFilters = z.infer<typeof PlanYearlyFiltersSchema>;

//Query
export const PlanYearlyQuerySchema = z.object({
  filters: PlanYearlyFiltersSchema.partial(),
});
export type PlanYearlyQuery = z.infer<typeof PlanYearlyQuerySchema>;

//Define the response
export type PlanYearlyResponse = PlanYearly;

//Define the endpoint
export type PlanYearlyEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql-report/plan-yearly',
  PlanYearlyResponse,
  undefined,
  PlanYearlyQuery,
  undefined
>;
